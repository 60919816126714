import { useNavigate, useParams } from "react-router-dom";
import { RecordModel } from "pocketbase";
import React, { useEffect } from "react";
import { pb } from "../../App";
import { Button, Chip, Skeleton } from "@nextui-org/react";
import Typography from "../../styles/Typography";

const sampleStudentSumissions = [
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:31:05.402Z",
    gpt_explain: "",
    id: "ej458i2bptkzg72",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:31:15.068Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:29:30.022Z",
    gpt_explain: "",
    id: "a8aw65likluloy5",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:29:38.427Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:28:04.048Z",
    gpt_explain: "",
    id: "5oix9q3pk0fka4m",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:28:45.231Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:27:31.023Z",
    gpt_explain: "",
    id: "erlsaxqeyowb9sl",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "te",
    updated: "2024-02-28 01:27:42.753Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:26:32.591Z",
    gpt_explain: "",
    id: "64r3rce9namxy50",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:27:00.373Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:25:55.621Z",
    gpt_explain: "",
    id: "b19rbhb25xwkppu",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:26:04.038Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:19:10.385Z",
    gpt_explain: "",
    id: "lin6xzrc00kbxn4",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:21:52.485Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:18:23.484Z",
    gpt_explain: "",
    id: "u760p4wjwwnwlhp",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "test",
    updated: "2024-02-28 01:18:33.952Z",
  },
  {
    collectionId: "4wzqj975r1m7gln",
    collectionName: "vocab_fitb_quizzes_submissions",
    created: "2024-02-28 01:01:00.622Z",
    gpt_explain: "",
    id: "5bmzznqh2ixfqze",
    quiz: "6mm3bunbj02ahdb",
    score: 10,
    status: "finished",
    student_answer: {
      A1: "ecosystem",
    },
    student_name_or_id: "Hello",
    updated: "2024-02-28 01:01:09.716Z",
  },
];

interface QuizDetailsUserInputProps {
  additional_vocab?: string;
  level?: string;
  number_of_vocab?: string;
  pos?: boolean;
  topic: string;
  vocab_list: string;
}

export const QuizPage: React.FC = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState({
    generateQuiz: false,
    generateExplanation: false,
    getResult: false,
    deleteQuiz: false,
  });
  const [dataValidation, setDataValidation] = React.useState("");
  const [studentSubmissions, setStudentSubmissions] = React.useState<
    RecordModel[]
  >([]);
  const { quizId } = useParams();
  const [quiz, setQuiz] = React.useState<RecordModel | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const quiz = await pb
        .collection("vocab_fitb_quizzes")
        .getOne(quizId ?? "");
      setQuiz(quiz);
      const studentSubmissions = await pb
        .collection("vocab_fitb_quizzes_submissions")
        .getFullList({
          sort: "-created",
          filter: pb.filter("quiz = {:id}", { id: quizId }),
        });
      setStudentSubmissions(studentSubmissions);
      console.log(studentSubmissions);
    };

    fetchData();
    setIsLoaded(true);
  }, [quizId]);

  function handleGenerateExplanation() {
    fetch(
      `https://molly-upgradehk-pb.pockethost.io/azure/process/s2gou72dhaaxxsv/quiz/${quizId}/explain`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        window.location.reload();
        // Handle the data received from the request
      })
      .catch((error) => {
        console.error("Error:", error);
        window.alert("(Please regenerate the explaination again. Error: " + error);
        window.location.reload();
        // Handle the error
      });
  }

  function handleDeleteQuiz(quizId: string) {
    setDataValidation("Loading...");
    pb.collection("vocab_fitb_quizzes").delete(quizId);
    setTimeout(() => {
      navigate("/quiz");
    }, 1000);
  }
  function handleGenerateQuiz() {
    fetch(
      `https://molly-upgradehk-pb.pockethost.io/azure/process/t9t8tqw239nkf16/quiz/${quizId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        window.location.reload();
        // Handle the data received from the request
      })
      .catch((error) => {
        console.error("Error:", error);
        window.alert("(Please regenerate the quiz again. Error: " + error);
        window.location.reload();
        // Handle the error
      });
  }

  const sortedVocabularies = Object.entries(
    quiz?.result_mixed_answers?.answers ?? {}
  ).sort(([keyA], [keyB]) => {
    const indexA = parseInt(keyA.slice(1));
    const indexB = parseInt(keyB.slice(1));
    return indexA - indexB;
  });
  return (
    <div className='flex flex-col p-4 w-full h-dvh overflow-y-auto gap-4 pb-8'>
      <p className='font-semibold mb-2 text-lg'>Quiz Information 📑</p>
      <div className='flex flex-col gap-2'>
        <small>
          Quiz Actions{" "}
          {dataValidation != "" && (
            <span className='text-red-600 text-bold'>{dataValidation}</span>
          )}
        </small>
        {!isLoaded && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-full md:w-80 h-10 rounded-lg'
          >
            {" "}
          </Skeleton>
        )}
        <div className='flex flex-row gap-2 items-center flex-wrap'>
          <Button
            variant='solid'
            color='primary'
            size='sm'
            onClick={() => {
              navigate(`/quiz/${quizId}/edit`);
            }}
          >
            Edit Quiz
          </Button>
          <Button
            variant='flat'
            color='success'
            size='sm'
            onClick={() => {
              navigate(`/quiz/${quizId}/student`);
            }}
          >
            Student View
          </Button>

          <Button
            variant='flat'
            color='warning'
            size='sm'
            onClick={() => {
              setLoading({ ...loading, generateQuiz: true });
              handleGenerateQuiz();
            }}
          >
            {!loading.generateQuiz && "Generate Quiz"}{" "}
            {loading.generateQuiz && "(Loading...)"}
          </Button>

          {(quiz?.result_mixed_answers && (
            <Button
              variant='flat'
              color='secondary'
              size='sm'
              onClick={() => {
                setLoading({ ...loading, generateExplanation: true });
                handleGenerateExplanation();
              }}
            >
              {!loading.generateExplanation && "Generate Explanation"}{" "}
              {loading.generateExplanation && "(Loading...)"}
            </Button>
          )) ||
            null}

          <Button
            variant='flat'
            color='danger'
            size='sm'
            onClick={() => {
              handleDeleteQuiz(quizId ?? "");
            }}
          >
            Delete Quiz
          </Button>
        </div>{" "}
      </div>
      <div className='flex flex-col gap-2'>
        <small>
          Students Submissions (By {studentSubmissions.length} students)
        </small>
        {!isLoaded && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-full md:w-80 h-10 rounded-lg'
          >
            {" "}
          </Skeleton>
        )}
        <div className='flex flex-row gap-2 items-center overflow-auto'>
          {studentSubmissions.map((submission, index) => (
            <button
              onClick={() => {
                navigate(`/quiz/${quizId}/student/${submission.id}`);
              }}
            >
              <Chip
                key={index}
                color={submission.score < 50 ? "danger" : "success"}
              >
                {submission.student_name_or_id}: {submission.score}%
              </Chip>
            </button>
          ))}
        </div>
      </div>
      <div className='flex flex-col gap-1'>
        <small>Quiz Name</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-full md:w-120 h-10 rounded-lg'
          ></Skeleton>
        )}
        <div className='flex flex-row gap-4 items-center'>
          {quiz && quiz?.quiz_name && (
            <h1 className={Typography("h2")}>{quiz?.quiz_name}</h1>
          )}
          
        </div>
      </div>
      <div className='flex flex-col gap-1'>
        <small>Quiz Topic</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-20 h-4 rounded-lg'
          ></Skeleton>
        )}
        {quiz && <p>{quiz?.quiz_details?.topic || "Not Provided by user"}</p>}
      </div>
      <div className='flex flex-col gap-1'>
        <small>Passage</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-full md:w-60 h-12 rounded-lg'
          ></Skeleton>
        )}
        {quiz && <p>{quiz?.result_passage || "Please generate quiz..."}</p>}
      </div>
      <div className='flex flex-col gap-1'>
        <small>
          Vocabularies List (
          {Object.keys(quiz?.result_mixed_answers?.answers ?? {}).length} in
          total)
        </small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-20 h-4 rounded-lg'
          ></Skeleton>
        )}

        {quiz && (
          <div className='flex flex-row gap-1 flex-wrap'>
            {sortedVocabularies.map(([key, value], index) => (
              <Chip key={index}>{`${key}: ${
                (value as { answer: string; pos: string }).answer
              } (${(value as { answer: string; pos: string }).pos})`}</Chip>
            ))}{" "}
          </div>
        )}
      </div>
      <div className='flex flex-col gap-1'>
        <small>Quiz Explanation</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-20 h-4 rounded-lg'
          ></Skeleton>
        )}
        {quiz && (
          <div>
            {Object.entries(quiz?.gpt_explain || {}).map(([key, value]) => (
              <p key={key}>{`${key}: ${value}`}</p>
            ))}
          </div>
        )}
      </div>

      <div className='flex flex-col gap-1'>
        <small>Additional Vocabularies</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-20 h-4 rounded-lg'
          ></Skeleton>
        )}
        {quiz && (
          <>
            {quiz?.result_mixed_answers?.additional_vocab && (
              <div className='flex flex-row gap-1 flex-wrap'>
                {quiz.result_mixed_answers.additional_vocab?.map(
                  (vocab: string, index: number) => (
                    <Chip key={index}>{vocab}</Chip>
                  )
                ) || "Not provided by User"}
              </div>
            )}
          </>
        )}
      </div>
      <div className='flex flex-col gap-1'>
        <small>Student Level (CEFR Level)</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-20 h-4 rounded-lg'
          ></Skeleton>
        )}
        {quiz && (
          <p>{quiz?.quiz_details?.level || "Not Available at the moment"}</p>
        )}
      </div>

      <div className='flex flex-col gap-1'>
        <small>Part of Speech</small>
        {!quiz && (
          <Skeleton
            isLoaded={isLoaded}
            className='w-20 h-4 rounded-lg'
          ></Skeleton>
        )}
        {quiz && (
          <p>
            {(quiz?.quiz_details?.pos && "Part of Speech is required") ||
              "Not Required by user"}
          </p>
        )}
      </div>
    </div>
  );
};

const gpt_explain_sample = {
  A1: "The correct answer is 'ceasefire' because it refers to an agreement to stop fighting temporarily during a conflict.",
  A2: "The correct answer is 'humanitarian' because it describes something that is concerned with or seeking to promote human welfare, especially during crises.",
  A3: "The correct answer is 'transparent' because it describes processes that are open, clear, and understandable, which is necessary to gain trust.",
  A4: "The correct answer is 'convoy' because it is a group of vehicles, typically motor vehicles or ships, traveling together for mutual support and protection.",
  A5: "The correct answer is 'escalation' because it describes a situation where things are becoming more intense or serious, usually referring to an increase in violence or tensions.",
  A6: "The correct answer is 'tragedy' because it refers to an event that causes great suffering, destruction, and distress, shocking people who hear about it.",
  A7: "The correct answer is 'independent' because it describes reporting that is free from outside control or influence, providing unbiased information.",
};
