import Typography from "../../styles/Typography";
import { pb } from "../../App";
import React, { useEffect } from "react";
import { RecordModel } from "pocketbase";
import { useNavigate } from "react-router";
import { stringify } from "querystring";
import { Button } from "@nextui-org/react";

export const PromptList: React.FC = () => {
  const [prompts, setPrompts] = React.useState<RecordModel[]>([]);
  const [authData, setAuthData] = React.useState<RecordModel | null>(null); // [1]
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const authData = await pb
        .collection("users")
        .getOne(pb.authStore.model?.id);
      setAuthData(authData);
      const prompts = await pb.collection("prompts").getFullList({
        sort: "-created",
      });
      setPrompts(prompts);
      console.log(prompts[0]);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className='relative w-full h-dvh overflow-y-auto flex flex-col  bg-white p-4'>
        <div className='flex flex-row gap-4 mb-4'>
          <p className=' font-bold pt-[2px]'>
            Available Prompts for Development
          </p>
          <Button
            className=''
            size='sm'
            variant='light'
            color='success'
          >
            Add Prompt
          </Button>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  gap-4'>
          {prompts.map((prompt) => (
            <div className='flex flex-col p-6 border justify-between'>
              <div className='flex flex-col gap-2'>
                <p key={prompt.id}>{prompt.name}</p>
                <p className='text-xs'>{prompt.description}</p>
              </div>
              <div className='flex flex-row gap-2 mt-4'>
                {" "}
                <Button
                  onClick={() => {
                    navigate(`/prompt/${prompt.id}`);
                  }}
                  className='mt-2 rounded-none border-1'
                  size='sm'
                  variant='bordered'
                  color='secondary'
                >
                  {" "}
                  View Prompt
                </Button>
                <Button
                  onClick={() => {
                    navigate(`/prompt/${prompt.id}`);
                  }}
                  className='mt-2 rounded-none border-1'
                  size='sm'
                  variant='bordered'
                  color='primary'
                >
                  {" "}
                  Edit
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
