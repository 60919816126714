import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { DashboardPage } from "./pages/dashboard/Dashboard";
import { LoginPage } from "./pages/auth/Login";
import { NavBar } from "./components/Navigation/NavBar";
import QuizCard from "./components/Quiz/QuizCard";
import { QuizPage } from "./pages/quizzes/QuizPage";
import { QuizzesPage } from "./pages/quizzes/QuizzesPage";
import { NotFoundPage } from "./pages/others/NotFoundPage";
import { MainProps } from "./props/MainProps";
import { CreateQuizPage } from "./pages/quizzes/CreateQuizPage";
import AppStudent from "./pages/studentview/AppStudent";
import { NavBarStudent } from "./pages/studentview/NavBarStudent";
import { SingleQuizStudent } from "./pages/studentview/SingleQuizStudent";
import { InitateQuiz } from "./pages/studentview/InitateQuiz";
import { EditQuizPage } from "./pages/quizzes/EditQuizPage";
import { PromptList } from "./pages/prompt/PromptList";
import { SinglePromptPage } from "./pages/prompt/SinglePromptPage";
import { SideBar } from "./components/Navigation/Sidebar";
import { NewQuizPage } from "./pages/testground/vocabquiz/CreateVocabQuizNew";

export const PageWithNavBarPaddingFix: React.FC<MainProps> = ({ children }) => {
  return (
    <div className='flex flex-row w-full min-h-[calc(100svh)]'>{children}</div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path='/dashboard'
        element={
          <App>
            <SideBar />
            <DashboardPage />
          </App>
        }
      />
      <Route
        path='/'
        element={
          <App>
            <SideBar />
            <DashboardPage />
          </App>
        }
      />
      <Route
        path='/login'
        element={<LoginPage />}
      />
      <Route
        path='/quiz/:quizId'
        element={
          <App>
            <SideBar />
            <QuizPage />
          </App>
        }
      />
      <Route
        path='/quiz'
        element={
          <App>
            <SideBar /> <QuizzesPage />
          </App>
        }
      />
      <Route
        path='/quiz/create'
        element={
          <App>
            <SideBar /> <CreateQuizPage />
          </App>
        }
      />
      <Route
        path='/quiz/:quizId/edit'
        element={
          <App>
            <SideBar /> <EditQuizPage />
          </App>
        }
      />
      <Route
        path='/prompt'
        element={
          <App>
            <SideBar /> <PromptList />
          </App>
        }
      />
      <Route
        path='/prompt/:promptId'
        element={
          <App>
            <SideBar /> <SinglePromptPage></SinglePromptPage>
          </App>
        }
      />
      <Route
        path='/new/quiz'
        element={
          <App>
            <SideBar /> <NewQuizPage/>
          </App>
        }
      />
      <Route
        path='/quiz/:quizId/student'
        element={
          <AppStudent>
            <PageWithNavBarPaddingFix>
              <InitateQuiz />
            </PageWithNavBarPaddingFix>
          </AppStudent>
        }
      />
      <Route
        path='/quiz/:quizId/student/:submissionId'
        element={
          <AppStudent>
            <PageWithNavBarPaddingFix>
              <SingleQuizStudent />
            </PageWithNavBarPaddingFix>
          </AppStudent>
        }
      />
      <Route
        path='*'
        element={
          <App>
            <SideBar /> <NotFoundPage />
          </App>
        }
      />
      ;
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
