import { Button, Divider } from "@nextui-org/react";
import { useNavigate } from "react-router";
import { pb } from "../../App";
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/solid";
import {
  SwatchIcon,
  RectangleGroupIcon,
  DocumentTextIcon,
  Bars3Icon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { useContext } from "react";
import MobileContext from "../../context/IsMobileContext";
import { useEffect, useState } from "react";

export const SideBar: React.FC = () => {
  const isMobile = useContext(MobileContext);
  const [isSideBarOpen , setIsSideBarOpen] = useState(false);

  const menuItems = [
    {
      "Vocab Quiz": "/quiz",
      icon: <SwatchIcon className='w-4 h-4 -ml-1 '></SwatchIcon>,
    },
    {
      "Development Prompts": "/prompt",
      icon: <DocumentTextIcon className='w-4 h-4 -ml-1 '></DocumentTextIcon>,
    },
  ];

  const menuItemsExp = [
    {
      "Vocab Quiz (New)": "/new/quiz",
      icon: <SwatchIcon className='w-4 h-4 -ml-1 '></SwatchIcon>,
    },
    {
      "Passage to QA Set": "/ptqas",
      icon: (
        <RectangleGroupIcon className='w-4 h-4 -ml-1 '></RectangleGroupIcon>
      ),
    },
  ];

  const navigate = useNavigate();
  const currentSlug = "/" + window.location.pathname.split("/").pop();
  console.log(currentSlug);
  return (
    <>
      
      {isMobile && (<>
      <div className="flex flex-row bg-white border-b-1 px-4 py-2 justify-between">
      <div className='flex flex-row gap-2 items-center'>
          <img
            src='/logo500x500.png'
            alt='MAP Logo'
            className='w-5 h-5'
          />
          <h1
            className='font-bold text-sm'
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            UpgradeHK MAP
          </h1>
        </div>
        <Button
        isIconOnly
        onPress={() => setIsSideBarOpen(!isSideBarOpen)}
        className={` bg-white w-fit rounded-md p-0 m-0 `}
      >
        <Bars3Icon className='w-4 h-4'></Bars3Icon>
      </Button>

        </div></>)}
      <div
        className={`relative flex flex-col h-screen min-w-60 border-r-1 p-4 gap-0 left-0 top-0 ${
          isSideBarOpen || !isMobile  ? "show" : "hidden"
        }`}
      >
        
     
       <div className={`flex flex-row gap-2 items-center ${isMobile ? 'hidden' : 'show'}`}>
          <img
            src='/logo500x500.png'
            alt='MAP Logo'
            className='w-5 h-5'
          />
          <h1
            className='font-bold text-sm'
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            UpgradeHK MAP
          </h1>
        </div>
        

        <div className={`flex flex-col gap-1 ${isMobile ? '' : 'mt-6 '}`}>
          <small className='font-normal mb-1 text-xs text-gray-500'>
            Available Tools
          </small>
          {menuItems.map((item) => (
            <Button
              size='sm'
              variant='light'
              onClick={() => {
                navigate(Object.values(item)[0]);
              }}
              className={`w-full items-center justify-start flex flex-row gap-2 font-normal ${
                currentSlug === Object.values(item)[0]
                  ? "bg-green-100 font-normal"
                  : ""
              }`}
            >
              {item.icon}
              {Object.keys(item)[0]}
            </Button>
          ))}
        </div>
        <div className='flex flex-col gap-1 mt-4 mb-6 '>
          <small className='font-normal mb-1 text-xs text-gray-500'>
            Experimental
          </small>
          {menuItemsExp.map((item) => (
            <Button
              size='sm'
              variant='light'
              onClick={() => {
                navigate(Object.values(item)[0]);
              }}
              className={`w-full items-center justify-start flex flex-row gap-2 font-normal ${
                currentSlug === Object.values(item)[0]
                  ? "bg-green-100 font-normal"
                  : ""
              }`}
            >
              {item.icon}
              {Object.keys(item)[0]}
            </Button>
          ))}
        </div>
        <Divider></Divider>
        <div className='flex flex-col gap-1 my-6'>
          <Button
            size='sm'
            variant='light'
            onClick={() => {
              pb.authStore.clear();
              navigate("/login");
            }}
            className='w-full items-center justify-start flex flex-row gap-2 font-normal'
          >
            <ArrowLeftStartOnRectangleIcon className='w-4 h-4 -ml-1'></ArrowLeftStartOnRectangleIcon>{" "}
            Logout
          </Button>
        </div>
      </div>
      
    </>
  );
};
