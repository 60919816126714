import { useNavigate, useParams } from "react-router-dom";
import { RecordModel } from "pocketbase";
import React, { useEffect } from "react";
import { pb } from "../../App";

export const InitateQuiz: React.FC = () => {
  const { quizId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const quiz = await pb
        .collection("vocab_fitb_quizzes")
        .getOne(quizId ?? "");
      const quizSubmissionRecord = await pb
        .collection("vocab_fitb_quizzes_submissions")
        .create({ status: "created" });
      navigate(`/quiz/${quizId}/student/${quizSubmissionRecord.id}`);
    };

    fetchData();
  }, [quizId]);

  return (
    <div className='flex flex-col p-4 w-full h-full gap-4 bg-gray-50'>
      Loading...
    </div>
  );
};
