export default function Typography(type: string) {
  if (type === "h1") {
    return "flex flex-row font-bold text-4xl items-center";
  }
  if (type === "h2") {
    return "flex flex-row font-bold text-3xl items-center";
  }
  if (type === "h3") {
    return "flex flex-row font-bold text-2xl items-center";
  }
  if (type === "h4") {
    return "flex flex-row font-bold text-xl items-center";
  }
  if (type === "h5") {
    return "flex flex-row font-bold text-lg items-center";
  }
  if (type === "h6") {
    return "flex flex-row font-bold text-base items-center";
  }
  if (type === "p") {
    return "flex flex-row text-base items-center";
  }
  if (type === "a") {
    return "flex flex-row text-base items-center";
  }
  if (type === "span") {
    return "flex flex-row text-base items-center";
  }
}
