import React, { ReactNode, useContext, useEffect, useState } from "react";
import { NextUIProvider } from "@nextui-org/react";
import MobileContext, { MobileProvider } from "./context/IsMobileContext";
import { MainProps } from "./props/MainProps";
import PocketBase from "pocketbase";
import { useNavigate } from "react-router";

export const pb = new PocketBase("https://molly-upgradehk-pb.pockethost.io/");

const App: React.FC<MainProps> = ({ children }) => {
  const isAuthenticated = pb.authStore.isValid;
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);

  const [isMobileApp, setIsMobileApp] = useState(window.innerWidth < 768); // Adjust breakpoint

  useEffect(() => {
    const handleResize = () => setIsMobileApp(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  
  
  useEffect(() => {
    if (isAuthenticated == false) {
      navigate("/login");
    }
  }, []);

  return (
    <NextUIProvider>
      <MobileProvider>
      {" "}
      {isAuthenticated && (
        <div className={`w-screen min-h-[calc(100svh)] bg-white flex ${isMobileApp ? 'flex-col' : 'flex-row' }`}>
          <>{children}</>
        </div>
      )}
      </MobileProvider>
    </NextUIProvider>
  );
};

export default App;
