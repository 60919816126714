import React, { useEffect } from "react";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useNavigate } from "react-router-dom";
import { pb } from "../../App";
import { Button, Card, Input, Spinner } from "@nextui-org/react";

export const LoginPage: React.FC = () => {
  const [status, setStatus] = React.useState("");
  const [loginData, setLoginData] = React.useState({ email: "", password: "" });
  const isAuthenticated = pb.authStore.isValid;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  });

  async function handleSubmit() {
    setStatus("Loading...");
    try {
      const authData = await pb
        .collection("users")
        .authWithPassword(loginData.email, loginData.password);
      setStatus("");
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      setStatus("Invalid email or password, please try again.");
    }
  }

  return (
    <div className='p-4 bg-white w-screen h-[calc(100dvh)]'>
      <div className='flex flex-col gap-2 w-full h-full items-center justify-center '>
        <Card className='flex flex-col gap-4 items-center w-full md:w-[500px] py-20 px-8 '>
          <img
            src='/logo500x500.png'
            alt='MAP Logo'
            className='w-12 h-12'
          />

          <h1 className='text-xl font-bold mb-4 '>UpgradeHK MAP Login</h1>
          {status != "" && (
            <p
              className={`${
                status == "Invalid email or password, please try again." &&
                "text-red-700"
              } flex flex-row text-xs gap-2 items-center justify-center`}
            >
              {status}
              {status == "Loading..." && <Spinner size='sm' />}
            </p>
          )}

          <Input
            placeholder='Email'
            value={loginData.email}
            color={
              status == "Invalid email or password, please try again."
                ? "warning"
                : "primary"
            }
            onChange={(e) => {
              setLoginData({ ...loginData, email: e.target.value });
            }}
          />
          <Input
            type='password'
            placeholder='Password'
            value={loginData.password}
            color={
              status == "Invalid email or password, please try again."
                ? "warning"
                : "primary"
            }
            onChange={(e) => {
              setLoginData({ ...loginData, password: e.target.value });
            }}
          />
          <Button
            variant='solid'
            color={
              status == "Invalid email or password, please try again."
                ? "warning"
                : "primary"
            }
            className='w-full mt-4'
            onClick={handleSubmit}
          >
            {status == "Invalid email or password, please try again."
              ? "Retry Login"
              : "Login"}
          </Button>
          <Button
            className='p-1 m-0 w-full bg-white'
            onClick={() => {
              setLoginData({ email: "test@test.com", password: "testtest" });
              console.log(loginData);
            }}
          >
            Use Sample User to Login
          </Button>
        </Card>
      </div>
    </div>
  );
};
