import { pb } from "../../App";
import React, { useEffect, useState } from "react";
import { RecordModel } from "pocketbase";
import { useNavigate, useParams } from "react-router";

export const SinglePromptPage: React.FC = () => {
  const [prompt, setPrompt] = useState({} as RecordModel); // [1]
  const { promptId } = useParams<{ promptId: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const singlePrompt = await pb
        .collection("prompts")
        .getOne(promptId ?? "");
      console.log(singlePrompt);
      setPrompt(singlePrompt);
    };
    fetchData();
    console.log(promptId);
  }, []);

  return (
    <>
      <div className='relative w-full h-full flex flex-col gap-4 items-center justify-center bg-white px-4 py-8 pb-8'>
        {/**Name & Description */}
        <div className='flex flex-col gap-1 items-center'>
          <small>{promptId}</small>
          <p className=' font-bold '>{prompt.name}</p>
          {prompt.description && (
            <p className=' font-light '>{prompt.description}</p>
          )}
        </div>
        {/**Prompt */}
        {prompt.prompt && (
          <div className='flex flex-col gap-2  p-4 border rounded-md max-w-screen-md'>
            <small className='font-bold  '>Prompt</small>

            <small className=' font-light '>{prompt.prompt}</small>
          </div>
        )}
        {/**Input Structure */}
        {prompt.input_structure && (
          <div className='flex flex-col gap-2  p-4 border rounded-md max-w-screen-md'>
            <small className='font-bold  '>input_structure</small>

            <small className=' font-light '>{prompt.input_structure}</small>
          </div>
        )}

        {/**User Demo */}
        {prompt.demo && (
          <div className='flex flex-col gap-2  p-4 border rounded-md max-w-screen-md'>
            <small className='font-bold  '>User Demo</small>

            <small className=' font-light '>{prompt.demo}</small>
          </div>
        )}

        {/**Expected Output*/}

        <div className='flex flex-col gap-2  p-4 border rounded-md max-w-screen-md'>
          <small className='font-bold  '>Prompt</small>
          {prompt.description && (
            <small className=' font-light '>{prompt.prompt}</small>
          )}
        </div>
        {/**Remarks*/}
        <div className='flex flex-col gap-4 divide-y-1'></div>
      </div>
    </>
  );
};
