import React, { useEffect } from "react";
import { pb } from "../../App";
import { Button, Input, Textarea } from "@nextui-org/react";
import Typography from "../../styles/Typography";
import { RadioGroup, Radio } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router";

export const EditQuizPage: React.FC = () => {
  const navigate = useNavigate();
  const { quizId } = useParams<{ quizId: string }>();
  const [quiz, setQuiz] = React.useState({
    quiz_status: "initiated",
    quiz_name: "",
    quiz_details: {
      additional_vocab: "0",
      level: "",
      number_of_vocab: "Use All",
      pos: false,
      topic: "",
      vocab_list: "",
    },
    openai_status: {},
    openai_result: {},
    result_passage: "test",
    result_passage_array: {},
    result_mixed_answers: {},
    quiz_owner: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const authData = await pb
        .collection("users")
        .getOne(pb.authStore.model?.id);
      console.log(authData.id);
      const quizRecord = await pb
        .collection("vocab_fitb_quizzes")
        .getOne(quizId ?? ""); // Fix: Pass quizId.id instead of quizId
      console.log(quizRecord);
      setQuiz((prevQuiz) => ({ ...prevQuiz, ...quizRecord }));
    };
    fetchData();
  }, []);

  function handleUpdateQuiz(data: object) {
    console.log(data);
    pb.collection("vocab_fitb_quizzes").update(quizId ?? "", data); // Fix: Provide a default value for quizId
    setTimeout(() => {
      navigate(`/quiz/${quizId}`);
    }, 1000);
  }
  return (
    <div className='w-full h-dvh overflow-y-auto flex flex-col items-center justify-center bg-white px-4 py-8 pb-8'>
      <div className='bg-white h-fit w-full sm:w-[600px] flex flex-col gap-4'>
        <p className='font-semibold mb-2 text-lg'>Edit Quiz 📑</p>
        <div className='flex flex-col gap-1'>
          <small>Quiz Name (Optional)</small>

          <Input
            size='sm'
            variant='flat'
            color='primary'
            placeholder='e.g. Vocabulary Quiz 1'
            className='w-full'
            onChange={(e) => setQuiz({ ...quiz, quiz_name: e.target.value })}
            value={quiz.quiz_name}
          ></Input>
        </div>
        <div className='flex flex-col gap-1'>
          <small>Topic</small>
          <Input
            size='sm'
            variant='flat'
            placeholder='e.g. Animals, Food, etc.'
            color='primary'
            className='w-full'
            value={quiz.quiz_details.topic}
            onChange={(e) =>
              setQuiz({
                ...quiz,
                quiz_details: { ...quiz.quiz_details, topic: e.target.value },
              })
            }
          ></Input>
        </div>
        <div className='flex flex-col gap-1'>
          <small>Vocabulary List</small>
          <Textarea
            size='sm'
            className='w-full'
            variant='flat'
            placeholder='e.g. cat, dog, fish, etc. (separated by comma)'
            color='primary'
            minRows={10}
            value={quiz.quiz_details.vocab_list}
            onChange={(e) =>
              setQuiz({
                ...quiz,
                quiz_details: {
                  ...quiz.quiz_details,
                  vocab_list: e.target.value,
                },
              })
            }
          ></Textarea>
        </div>
        <div className='flex flex-col gap-1'>
          <small>Level</small>
          <Input
            size='sm'
            variant='flat'
            placeholder="Choose from CEFR's A1 - C2 or 'Use All' for all levels"
            color='primary'
            className='w-full'
            value={quiz.quiz_details.level}
            onChange={(e) =>
              setQuiz({
                ...quiz,
                quiz_details: { ...quiz.quiz_details, level: e.target.value },
              })
            }
          ></Input>
        </div>
        <div className='flex flex-row gap-2 w-full'>
          <div className='flex flex-col gap-1 w-1/2'>
            <small>Number of Vocabulary</small>
            <Input
              size='sm'
              variant='flat'
              color='primary'
              className='w-full'
              value={quiz.quiz_details.number_of_vocab}
              onChange={(e) =>
                setQuiz({
                  ...quiz,
                  quiz_details: {
                    ...quiz.quiz_details,
                    number_of_vocab: e.target.value,
                  },
                })
              }
            ></Input>
          </div>
          <div className='flex flex-col gap-1 w-1/2'>
            <small>Additional Vocabulary</small>
            <Input
              size='sm'
              variant='flat'
              color='primary'
              placeholder='e.g. Amount of additional vocab to be added to the quiz'
              className='w-full'
              value={quiz.quiz_details.additional_vocab}
              onChange={(e) =>
                setQuiz({
                  ...quiz,
                  quiz_details: {
                    ...quiz.quiz_details,
                    additional_vocab: e.target.value,
                  },
                })
              }
            ></Input>
          </div>
        </div>

        <div className='flex flex-col gap-1'>
          <small>Quiz Settings</small>
          <div className='flex flex-row gap-2'>
            <Button
              variant={(quiz.quiz_details.pos == false && `ghost`) || "solid"}
              size='sm'
              color='primary'
              onClick={() =>
                setQuiz({
                  ...quiz,
                  quiz_details: {
                    ...quiz.quiz_details,
                    pos: !quiz.quiz_details.pos,
                  },
                })
              }
            >
              {(quiz.quiz_details.pos && "Part of Speech Required") ||
                "Part of Speech Not Required"}
            </Button>
          </div>
        </div>
        <div className='flex flex-row w-full gap-2'>
          <Button
            variant='faded'
            size='sm'
            className='w-1/2'
            onClick={() => {
              navigate(`/quiz/${quizId}`);
            }}
          >
            Return
          </Button>
          <Button
            variant='solid'
            color='danger'
            size='sm'
            className='w-1/2'
            onClick={() => {
              handleUpdateQuiz(quiz);
            }}
          >
            Update Quiz
          </Button>
        </div>
      </div>
    </div>
  );
};
