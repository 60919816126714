import { NextUIProvider } from "@nextui-org/react";

import { MainProps } from "../../props/MainProps";

const AppStudent: React.FC<MainProps> = ({ children }) => {
  return (
    <NextUIProvider>
      {" "}
      <div className='w-screen h-[calc(100dvh)] overflow-y-auto bg-white'>
        {children}
      </div>
    </NextUIProvider>
  );
};

export default AppStudent;
