import React, { createContext, useState, useEffect } from 'react';

const MobileContext = createContext(false);

export const MobileProvider = ({ children }: any) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust breakpoint

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
};

export default MobileContext;