import React, { useEffect } from "react";
import { MainProps } from "../../props/MainProps";
import { useNavigate } from "react-router-dom";
import { pb } from "../../App";
import { Button, Card, Divider, Skeleton } from "@nextui-org/react";
import { RecordModel } from "pocketbase";
import QuizCard, { CreateQuizCard } from "../../components/Quiz/QuizCard";
import { BeakerIcon, DocumentTextIcon } from "@heroicons/react/24/solid";

export const QuizzesPage: React.FC<MainProps> = () => {
  const [loadedQuiz, setLoadedQuiz] = React.useState(false); // [1]
  const [quizzes, setQuizzes] = React.useState<RecordModel[]>([]);
  const [authData, setAuthData] = React.useState<RecordModel | null>(null); // [1]
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const authData = await pb
        .collection("users")
        .getOne(pb.authStore.model?.id);
      setAuthData(authData);
      const quizzes = await pb.collection("vocab_fitb_quizzes").getFullList({
        sort: "-created",
        filter: pb.filter("quiz_owner = {:id}", { id: authData.id }),
      });
      setQuizzes(quizzes);
      setLoadedQuiz(true);
      console.log(quizzes[0]);
    };
    fetchData();
  }, []);

  return (
    <div className='w-full h-dvh overflow-y-auto p-4 flex-wrap gap-2 pb-8'>
      <p className='font-semibold text-lg mb-4'>
        Fill in the blanks Quizzes 📑
      </p>
      <div className='flex flex-row gap-4 flex-wrap'>
        <CreateQuizCard />
        {quizzes.map((quiz) => (
          <>
            <QuizCard
              title={quiz.quiz_name || ""}
              studentLevel={quiz.quiz_details?.level || ""}
              numOfVocab={
                Object.keys(quiz?.result_mixed_answers?.answers ?? {}).length ||
                0
              }
              quizTopic={quiz.quiz_details?.topic || ""}
              status={quiz.quiz_status || ""}
              passage={quiz.result_passage || ""}
              id={quiz.id}
            />
          </>
        ))}{" "}
      </div>{" "}
    </div>
  );
};

const data = [
  {
    collectionId: "tcbk9yhjan2aq19",
    collectionName: "vocab_fitb_quizzes",
    created: "2024-02-24 21:08:10.082Z",
    id: "md2k2d3c7twl3aj",
    openai_result: null,
    openai_status: null,
    quiz_details: null,
    quiz_name: "next",
    quiz_owner: "",
    quiz_status: "pending",
    result_mixed_answers: null,
    result_passage: "",
    updated: "2024-02-24 21:08:10.082Z",
  },
  {
    collectionId: "tcbk9yhjan2aq19",
    collectionName: "vocab_fitb_quizzes",
    created: "2024-02-22 17:39:29.518Z",
    id: "zasubz2mjkbuqm3",
    openai_result: {
      quiz_title: "Understanding Our Environment",
      quiz_body: {
        passage:
          "The balance of life on Earth greatly depends on the variety of organisms; this is known as [A1]. Reducing [A2] is a responsibility that both individuals and companies must embrace to mitigate climate change. The shift towards [A3] showcases our efforts to move away from fossil fuels and reduce [A4]. By adopting [A5] habits and products, we can contribute to preserving nature and limiting the release of [A6]. The [A7] of natural resources is a deliberate process to ensure their sustainability for future generations. [A8] is a comprehensive approach that aims to meet the needs of the present while considering the welfare of future humans and the planet. The adverse effects of [A9] on land, water, and air can create serious health risks and degrade the [A10].",
        answers: {
          A1: {
            answer: "Biodiversity",
            pos: "noun",
          },
          A2: {
            answer: "Carbon footprint",
            pos: "noun",
          },
          A3: {
            answer: "Renewable energy",
            pos: "noun",
          },
          A4: {
            answer: "Deforestation",
            pos: "noun",
          },
          A5: {
            answer: "Eco-friendly",
            pos: "adj.",
          },
          A6: {
            answer: "Greenhouse gases",
            pos: "noun",
          },
          A7: {
            answer: "Conservation",
            pos: "noun",
          },
          A8: {
            answer: "Sustainable development",
            pos: "noun",
          },
          A9: {
            answer: "Pollution",
            pos: "noun",
          },
          A10: {
            answer: "Ecosystem",
            pos: "noun",
          },
        },
        additional_vocabularies: [
          "Climate change",
          "Recycling",
          "Organic farming",
          "Habitat destruction",
          "Environmental impact",
        ],
      },
    },
    openai_status: {
      run_id: "run_sthyrR5g1DZiCBXGAkyDvnkB",
      status: "queued",
      thread_id: "thread_4i2DdTxut9oYJPunt5V9nWHy",
    },
    quiz_details: {
      additional_vocab: "5",
      level: "",
      number_of_vocab: "use all",
      pos: true,
      topic: "About environment",
      vocab_list:
        "Biodiversity, Carbon footprint, Renewable energy, Deforestation, Eco-friendly, Greenhouse gases, Conservation, Sustainable development, Pollution, Ecosystem",
    },
    quiz_name: "Understanding Our Environment",
    quiz_owner: "",
    quiz_status: "pending",
    result_mixed_answers: {
      additional_vocab: [
        "Climate change",
        "Recycling",
        "Organic farming",
        "Habitat destruction",
        "Environmental impact",
      ],
      answers: {
        A1: {
          answer: "Biodiversity",
          pos: "noun",
        },
        A10: {
          answer: "Ecosystem",
          pos: "noun",
        },
        A2: {
          answer: "Carbon footprint",
          pos: "noun",
        },
        A3: {
          answer: "Renewable energy",
          pos: "noun",
        },
        A4: {
          answer: "Deforestation",
          pos: "noun",
        },
        A5: {
          answer: "Eco-friendly",
          pos: "adj.",
        },
        A6: {
          answer: "Greenhouse gases",
          pos: "noun",
        },
        A7: {
          answer: "Conservation",
          pos: "noun",
        },
        A8: {
          answer: "Sustainable development",
          pos: "noun",
        },
        A9: {
          answer: "Pollution",
          pos: "noun",
        },
      },
    },
    result_passage:
      "The balance of life on Earth greatly depends on the variety of organisms; this is known as [A1]. Reducing [A2] is a responsibility that both individuals and companies must embrace to mitigate climate change. The shift towards [A3] showcases our efforts to move away from fossil fuels and reduce [A4]. By adopting [A5] habits and products, we can contribute to preserving nature and limiting the release of [A6]. The [A7] of natural resources is a deliberate process to ensure their sustainability for future generations. [A8] is a comprehensive approach that aims to meet the needs of the present while considering the welfare of future humans and the planet. The adverse effects of [A9] on land, water, and air can create serious health risks and degrade the [A10].",
    updated: "2024-02-24 08:22:24.219Z",
  },
];
