import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-fit w-full flex-col items-center justify-center gap-8 bg-white p-4 pt-24">
      <iframe
        title="404 json"
        className="h-40 w-40 rounded-md border-2 border-gray-300 md:h-80 md:w-80"
        src="https://lottie.host/embed/540a1c92-49aa-40e4-a9a1-9c51e51c2193/i2zFRnMDhs.json"
      ></iframe>

      <Button
        size="sm"
        variant="shadow"
        color="warning"
        onClick={() => {
          navigate("/dashboard");
        }}
        className="mt-4 w-fit"
      >
        Go Back
      </Button>
    </div>
  );
};
