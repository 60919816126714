import React from "react";
import { Button } from "@nextui-org/react";
import {
  Card,
  CardHeader,
  CardBody,
  Image,
  Chip,
  CardFooter,
} from "@nextui-org/react";
import { useNavigate } from "react-router";
import { DocumentTextIcon } from "@heroicons/react/24/solid";

interface QuizCardProps {
  title: string;
  numOfVocab: number;
  studentLevel: string;
  quizTopic: string;
  status: string;
  passage: string;
  id: string;
}

export default function QuizCard({
  title,
  numOfVocab,
  studentLevel,
  quizTopic,
  status,
  passage,
  id,
}: QuizCardProps) {
  const navigate = useNavigate();
  return (
    <Card className='py-4 px-2  w-full md:w-80 h-70'>
      <CardHeader className='pb-0 pt-2 flex-col items-start'>
        {" "}
        <h4 className='font-bold text-large'>{title}</h4>
        <small className='text-default-500'>
          {quizTopic} #{id}
        </small>
      </CardHeader>
      <CardBody className=' py-2 h-fit overflow-hidden'>
        <small>{passage.split(" ").slice(0, 30).join(" ")}...</small>
      </CardBody>
      <CardFooter className='flex flex-row gap-4 justify-between pr-2'>
        <div className='flex flex-row gap-2 pt-2 w-fit overflow-scroll no-scrollbar'>
          <Chip
            size='sm'
            color={
              status === "completed"
                ? "success"
                : status === "pending"
                ? "warning"
                : "default"
            }
          >
            {status}
          </Chip>{" "}
          {studentLevel == null && <Chip size='sm'>Level {studentLevel}</Chip>}
          <Chip size='sm'>{numOfVocab} Vocab</Chip>{" "}
        </div>
        <Button
          size='sm'
          color='primary'
          onClick={() => {
            navigate(`/quiz/${id}`);
          }}
        >
          Review
        </Button>
      </CardFooter>
    </Card>
  );
}

export function CreateQuizCard() {
  const navigate = useNavigate();
  return (
    <button
      className=' w-full md:w-80 h-70'
      onClick={() => {
        navigate("/quiz/create");
      }}
    >
      <Card className='flex flex-col gap-2 p-6 h-full w-full md:w-80 bg-slate-100 border-dashed border-blue-400 items-center justify-center'>
        <DocumentTextIcon className='h-12 w-12 text-blue-400' />
        <p className=' text-blue-400'>Create Quiz</p>
      </Card>
    </button>
  );
}
