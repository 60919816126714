import { useNavigate, useParams } from "react-router-dom";
import { RecordModel } from "pocketbase";
import React, { useEffect } from "react";
import { pb } from "../../App";
import { Button, Chip, Input, Spinner } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

import {
  CheckIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

export const SingleQuizStudent: React.FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { quizId, submissionId } = useParams();
  const [pageStatus, setPageStatus] = React.useState("");
  const [copySuccess, setCopySuccess] = React.useState(0);
  const [submissionData, setSubmissionData] =
    React.useState<RecordModel | null>();
  const [step, setStep] = React.useState(0);
  const [quiz, setQuiz] = React.useState<RecordModel | null>(null);
  const [submitQuizStatus, setSubmitQuizStatus] = React.useState(false);
  const [userInputAnswer, setUserInputAnswer] = React.useState({});
  const [studentNameOrID, setStudentNameOrID] = React.useState("");
  const [quizAnswer, setQuizAnswer] = React.useState({});
  const [quizVocabularies, setQuizVocabularies] = React.useState<String[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const quiz = await pb
        .collection("vocab_fitb_quizzes")
        .getOne(quizId ?? "");
      try {
        const submissionRecord = await pb
          .collection("vocab_fitb_quizzes_submissions")
          .getOne(submissionId ?? "");
        if (
          submissionRecord.status == "finished" &&
          submissionRecord.student_answer != null
        ) {
          setStep(5);
          setSubmissionData(submissionRecord);
          setUserInputAnswer(submissionRecord.student_answer);
          console.log(submissionRecord.ai_processing);
        }
      } catch (error) {
        console.log("Error: ", quizId, submissionId);
        console.error(error);
        setPageStatus(`Submission ${submissionId} not found`);
        setStep(-1);

        //navigate(`/quiz/${quizId}/student/`);
      }

      setQuiz(quiz);
      setQuizAnswer(quiz?.result_mixed_answers?.answers ?? {});
      console.log(quiz);
    };

    fetchData();
  }, [quizId]);
  interface QuizVocabProps {
    answers: object;
    additional_vocab: string[];
  }
  const getAllAnswers = (data: QuizVocabProps) => {
    const answersArray = Object.values(data.answers).map(
      (answer) => answer.answer
    );
    const additionalVocabArray = data.additional_vocab ?? [];
    const allAnswers = [...answersArray, ...additionalVocabArray];
    const shuffledAnswers = allAnswers.sort(() => Math.random() - 0.5);
    setQuizVocabularies(shuffledAnswers);
    return shuffledAnswers;
  };

  const sortedVocabularies = Object.entries(
    quiz?.result_mixed_answers?.answers ?? {}
  ).sort(([keyA], [keyB]) => {
    const indexA = parseInt(keyA.slice(1));
    const indexB = parseInt(keyB.slice(1));
    return indexA - indexB;
  });

  function gptExplain() {
    if (submissionData?.gpt_explain == null) {
      pb.send(`/quiz/explain/init/${quizId}/${submissionId}`, {});
      pb.send(`/quiz/explain/${quizId}/${submissionId}`, {});
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      console.log("explain Quiz");
      return true;
    } else {
      return false;
    }
  }

  const studentAnswerStatus: { [key: string]: boolean } =
    sortedVocabularies.reduce(
      (result: { [key: string]: boolean }, [key, value], index) => {
        const isAnswerCorrect =
          userInputAnswer[key as keyof typeof userInputAnswer] ===
          (value as { answer: string; pos: string }).answer.toLowerCase();
        result[`A${index + 1}`] = isAnswerCorrect;
        return result;
      },
      {}
    );

  async function saveUserInputAnswer() {
    await pb
      .collection("vocab_fitb_quizzes_submissions")
      .update(submissionId ?? "", {
        student_answer: userInputAnswer,
        //score is a number
        score:
          (Object.entries(userInputAnswer).filter(([key, value]) => {
            return (
              (value as string).toLowerCase() ===
              (
                quizAnswer[key as keyof typeof quizAnswer] as { answer: string }
              ).answer.toLowerCase()
            );
          }).length /
            sortedVocabularies.length) *
          100,
        status: "finished",
        student_answer_correct: studentAnswerStatus,
        student_name_or_id: studentNameOrID,
        quiz: quizId,
      });

    await pb
      .collection("vocab_fitb_quizzes_submissions")
      .getOne(submissionId ?? "")
      .then((data) => {
        setSubmissionData(data);
        setSubmitQuizStatus(true);
        setStep(5);
      });
  }

  return (
    <div className='bg-white w-full flex flex-col gap-2 items-center justify-center px-4 py-16 overflow-y-auto'>
      {(quiz != null && step == 0 && (
        <div className='flex flex-col gap-2 items-center justify-center'>
          <h1 className='font-bold text-2xl text-center'>{quiz.quiz_name}</h1>
          <p className='font-medium text-xs'>
            QuizID: {quiz?.id} | SubmissionID: {submissionId}
          </p>
          <Input
            variant='bordered'
            color='primary'
            placeholder='Your name or ID'
            className='w-full my-4 text-center text-iphone'
            inputMode='text'
            value={studentNameOrID}
            onChange={(e) => {
              setStudentNameOrID(e.target.value);
            }}
          ></Input>
          <Button
            color='primary'
            size='sm'
            className='mt-2 px-8'
            onClick={() => {
              setStep(1);
              getAllAnswers(quiz?.result_mixed_answers ?? {});
            }}
          >
            Start Quiz
          </Button>
        </div>
      )) ||
        (step == 0 && <Spinner color='success' />) ||
        (step == -1 && (
          <div className='flex flex-col gap-6 items-center'>
            <p className='font-semibold text-xl'>
              Submission{" "}
              <span className='font-light text-gray-400'>{submissionId}</span>{" "}
              not found
            </p>
            <iframe
              title='404 json'
              className='rounded-md border-2 border-gray-300 w-40 h-40 md:w-80 md:h-80'
              src='https://lottie.host/embed/540a1c92-49aa-40e4-a9a1-9c51e51c2193/i2zFRnMDhs.json'
            ></iframe>

            <Button
              color='danger'
              onClick={() => {
                navigate(`/quiz/${quizId}/student`);
              }}
            >
              Back to Quiz {quiz?.id}
            </Button>
          </div>
        ))}
      {step == 1 && (
        <div className='w-full flex flex-col gap-2 items-center justify-center md:w-[600px]'>
          <h1 className='font-bold text-2xl text-center'>{quiz?.quiz_name}</h1>
          <div className='flex flex-row gap-2 justify-center w-full md:w-[600px] flex-wrap mt-8'>
            {quizVocabularies.map((answer, index) => (
              <Chip>{answer}</Chip>
            ))}
          </div>
          <div className='flex flex-row gap-2 justify-center items-center w-full md:w-[800px] flex-wrap mt-8'>
            {quiz?.result_passage.replace(/\[A[0-9]+\]/g, (match: any) => {
              const index = parseInt(match.slice(2));
              return `A${index}._______`;
            })}
          </div>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 w-full md:w-[800px] h-fit flex-wrap justify-evenly mt-8'>
            {sortedVocabularies.map(([key, value], index) => (
              <div className='relative flex flex-row w-auto'>
                <Input
                  variant='flat'
                  color='primary'
                  placeholder={``}
                  className='w-full text-iphone'
                  onChange={(e) => {
                    setUserInputAnswer({
                      ...userInputAnswer,
                      [key]: e.target.value.toLowerCase(),
                    });
                    console.log(userInputAnswer);
                  }}
                />
                <div className='absolute z-10 right-2 top-[11px] flex flex-row gap-2'>
                  {quiz?.quiz_details.pos == true && (
                    <Chip
                      variant='flat'
                      color='primary'
                    >{`${
                      (value as { answer: string; pos: string }).pos
                    }`}</Chip>
                  )}
                  <Chip
                    variant='flat'
                    className='bg-white'
                  >{`${key}`}</Chip>
                </div>
              </div>
            ))}
          </div>
          <Button
            color='primary'
            className='mt-8 w-full'
            onClick={onOpen}
          >
            Submit Quiz
          </Button>

          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement='center'
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className='flex flex-col gap-1'>
                    Submit Quiz
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Please ensure your answers are correct before submitting
                    </p>
                    <small>Your name or ID (Recommended)</small>
                    <Input
                      variant='flat'
                      color='primary'
                      placeholder='Your name or ID'
                      value={studentNameOrID}
                      className='w-full'
                      onChange={(e) => {
                        setStudentNameOrID(e.target.value);
                      }}
                    ></Input>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color='danger'
                      variant='light'
                      onPress={onClose}
                      size='sm'
                    >
                      Close
                    </Button>
                    <Button
                      color='primary'
                      size='sm'
                      onClick={() => {
                        saveUserInputAnswer();
                      }}
                      isLoading={submitQuizStatus}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </div>
      )}

      {step == 5 && (
        <div className='w-full h-full flex flex-col gap-2 items-center justify-center md:w-[600px]'>
          <p className='font-medium text-xs flex flex-row items-center justify-center'>
            {submissionData?.student_name_or_id} (ID:
            {submissionData?.quiz}){" "}
            <button
              className='flex flex-row gap-1'
              onClick={() => {
                const link = window.location.href;
                navigator.clipboard.writeText(link);
                setCopySuccess(copySuccess + 1);
              }}
            >
              <DocumentDuplicateIcon className='w-4 h-4 ml-2 text-zinc-400 hover:text-gray-700' />{" "}
              <p className='text-gray-600'>
                {copySuccess == 0 ? "" : "Link Copied"}
              </p>
            </button>
          </p>
          <h1 className='font-bold text-2xl text-center'>Quiz Result</h1>
          <div className='flex flex-row gap-2 justify-center w-full md:w-[600px] flex-wrap mt-4'>
            <p
              className={
                `text-2xl font-semibold p-2 px-8 border-2 rounded-md` +
                ` ${
                  submissionData?.score >= 50
                    ? "bg-green-200 text-green-600"
                    : "bg-red-200 text-red-600"
                }`
              }
            >
              Correctness: {submissionData?.score.toFixed(1)}%
            </p>
          </div>
          <div className='flex flex-row gap-2 justify-center items-center w-full md:w-[800px] flex-wrap mt-8'>
            {sortedVocabularies.map(([key, value], index) => (
              <Chip
                key={index}
                className='w-full border text-white'
                startContent={
                  userInputAnswer[key as keyof typeof userInputAnswer] ===
                  (
                    value as { answer: string; pos: string }
                  ).answer.toLowerCase() ? (
                    <>
                      <CheckIcon className='text-white w-4 h-4' />
                    </>
                  ) : (
                    <>
                      <XMarkIcon className='text-white w-4 h-4' />
                    </>
                  )
                }
                color={
                  userInputAnswer[key as keyof typeof userInputAnswer] ===
                  (
                    value as { answer: string; pos: string }
                  ).answer.toLowerCase()
                    ? "success"
                    : "danger"
                }
              >{`A${index + 1}. ${
                userInputAnswer[key as keyof typeof userInputAnswer]
              }`}</Chip>
            ))}
          </div>

          <div className='flex flex-row gap-2 justify-center items-center w-full md:w-[800px] flex-wrap mt-8'>
            <p>{quiz?.result_passage}</p>
          </div>
          {quiz?.gpt_explain != null && (
            <>
              <p className='text-xl font-semibold mt-4'>
                Explain & Suggestions
              </p>
              <div className='flex flex-row gap-2 justify-center items-center w-full md:w-[800px] flex-wrap mt-8'>
                <>
                  {Object.entries(quiz?.gpt_explain).map(
                    ([key, value], index) => {
                      if (
                        !submissionData?.student_answer_correct[
                          key as keyof typeof submissionData.student_answer_correct
                        ]
                      ) {
                        return (
                          <div className='flex flex-row gap-2 p-4 bg-red-200 rounded-lg'>
                            <Chip color='danger'>${key}</Chip>{" "}
                            <p key={index}>{`${value}`}</p>
                          </div>
                        );
                      }
                      return null;
                    }
                  )}
                </>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const gpt_explain_sample = {
  A1: "The correct answer is 'ceasefire' because it refers to an agreement to stop fighting temporarily during a conflict.",
  A2: "The correct answer is 'humanitarian' because it describes something that is concerned with or seeking to promote human welfare, especially during crises.",
  A3: "The correct answer is 'transparent' because it describes processes that are open, clear, and understandable, which is necessary to gain trust.",
  A4: "The correct answer is 'convoy' because it is a group of vehicles, typically motor vehicles or ships, traveling together for mutual support and protection.",
  A5: "The correct answer is 'escalation' because it describes a situation where things are becoming more intense or serious, usually referring to an increase in violence or tensions.",
  A6: "The correct answer is 'tragedy' because it refers to an event that causes great suffering, destruction, and distress, shocking people who hear about it.",
  A7: "The correct answer is 'independent' because it describes reporting that is free from outside control or influence, providing unbiased information.",
};

const student_answer_correct_sample = {
  A1: false,
  A2: true,
  A3: false,
  A4: false,
  A5: false,
  A6: false,
  A7: false,
};
